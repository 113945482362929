import React from 'react';
import Auth from '../component/Auth';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify'; 
import Web3 from 'web3';

function Txnhistory() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const tokenName = localStorage.getItem('tokenName');
    const storedAddress = localStorage.getItem('userWallet');
  
    useEffect(() => {
      const bsc_key = process.env.REACT_APP_BSC_KEY;
      const tokenContractAddress = localStorage.getItem('tokenContractAddress');

      const fetchTransactions = async () => {
        try {
          const response = await fetch(
            `https://api.bscscan.com/api?module=account&action=tokentx&contractaddress=${tokenContractAddress}&address=${storedAddress}&page=1&offset=10000&startblock=0&endblock=999999999&sort=desc&apikey=${bsc_key}`
          );
          const data = await response.json();
          if (data.status === "1") {
            setTransactions(data.result);
          } else {
            setError('Failed to fetch transactions.');
          }
        } catch (error) {
          setError('Error fetching data.');
        } finally {
          setLoading(false);
        }
      };
  
      fetchTransactions();
    }, []);
  
    if (loading) {
      return <div>Loading...</div>; // You can style this loading state as needed
    }
  
    if (error) {
      return <div>{error}</div>; // Display error message if there is an error
    }

    return (
        <>
         <Auth/>
        <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
            <div className="relative z-20 w-full">
                <div className="px-6 pt-6 pb-8">
                    <div className="flex justify-start items-center pb-8 mr-8">
                        <a href="/" className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900">
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">Transaction</h1>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 pt-5">
                        {transactions.map((transaction, index) => (
                            <div key={index} className="p-4 rounded-lg bg-white bg-opacity-5">
                                <div className="flex justify-between items-center gap-2">
                                    <div className="border-t border-white border-dashed border-opacity-10 w-full"></div>
                                    <p className="text-xs text-n70 text-nowrap">
                                        {new Date(transaction.timeStamp * 1000).toLocaleString()} {/* Format timestamp */}
                                    </p>
                                    <div className="border-t border-white border-dashed border-opacity-10 w-full"></div>
                                </div>
                                <div className="flex justify-between items-center pt-3">
                                    <div className="flex justify-start items-start gap-3">
                                        <div className={`text-2xl ${transaction.from.toLowerCase() === storedAddress.toLowerCase() ? 'text-g300' : 'text-red-400'} flex justify-center items-center p-2 rounded-full bg-white bg-opacity-5`}>
                                            <i className={transaction.from.toLowerCase() === storedAddress.toLowerCase() ? "ph ph-arrow-up" : "ph ph-arrow-down"}></i>
                                        </div>
                                        <div className="">
                                            <p className="font-semibold pb-1">{transaction.from.toLowerCase() === storedAddress.toLowerCase() ? 'Sent' : 'Received'} {tokenName}</p>
                                            <p className={`text-sm bg-white bg-opacity-5 px-3 py-1 rounded-md ${transaction.from.toLowerCase() === storedAddress.toLowerCase() ? 'text-g300' : 'text-red-400'}`}>
                                                {transaction.blockHash ? 'Confirmed' : 'Pending'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="">
                                        <p className="font-semibold pb-1">{Web3.utils.fromWei(transaction.value, 'ether')} {tokenName}</p>
                                        <p className="text-sm text-end">
                                            <span className="text-g300">$</span>{/* You can add logic to calculate the USD value */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
         <ToastContainer />
        </>
    );
}

export default Txnhistory;
