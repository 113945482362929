import React from 'react'

function About() {
    return (
        <>
            <div className="container bg-n900 h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-start items-center pb-8 mr-8">
                        <a
                            href="/account"
                            className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
                        >
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">About</h1>
                        </div>
                    </div>
                    <div className="">
                        <div className="max-h-dvh overflow-y-auto mb-8 pr-4">
                            <p className="text-n70 pt-4">
                            Welcome to KCOIN Web3 Wallet, your gateway to the decentralized world. KCOIN is a fully on-chain wallet designed to offer seamless interaction with blockchain technologies, ensuring that your assets are always in your control. We believe in empowering users by providing a non-custodial solution where you hold the private keys and have full ownership of your digital assets.
                            </p>
                            <p className="text-n70 pt-4">
                            At KCOIN, security and privacy are paramount. Our wallet operates directly on the blockchain, ensuring that your transactions are transparent, secure, and free from third-party interference. However, it’s important to note that while we provide the tools to manage your assets, KCOIN is not responsible for any loss of assets resulting from misuse, forgotten private keys, or unauthorized access. Users are solely responsible for the security of their wallet credentials.
                            </p>
                            <p className="text-n70 pt-4">
                            By using KCOIN, you agree to assume full responsibility for the protection of your digital assets. We encourage you to take appropriate security measures, including regular backups of your private keys and using trusted devices for access. KCOIN Web3 Wallet offers freedom and flexibility in managing your assets, but with that freedom comes responsibility.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default About