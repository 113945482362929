import React from 'react'

function TopGainers() {
    return (
        <>
            <div
                class="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white"
            >
                <div
                    class="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]"
                ></div>

                <div class="px-6 py-8 relative z-20 w-full">
                    <div class="flex justify-start items-center pb-8 mr-8">
                        <a
                            href="/"
                            class="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
                        >
                            <i class="ph-bold ph-caret-left"></i>
                        </a>
                        <div class="flex justify-center items-center w-full">
                            <h1 class="font-semibold text-2xl">Top Gainers</h1>
                        </div>
                    </div>

                    <div class="flex flex-col gap-2 pt-5">
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#01</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/Ethereum_ETH.png" alt="" />
                                </div>
                                <p class="font-semibold">Ethereum</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#02</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/Bitcoin_icon.png" alt="" />
                                </div>
                                <p class="font-semibold">Bitcoin</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#03</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/Litecoin.png" alt="" />
                                </div>
                                <p class="font-semibold">Litecoin</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#04</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/Zcash.png" alt="" />
                                </div>
                                <p class="font-semibold">Zilliqa</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#05</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/Litecoin.png" alt="" />
                                </div>
                                <p class="font-semibold">Worldcoin</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#06</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/VeChain.png" alt="" />
                                </div>
                                <p class="font-semibold">BitDAO</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#07</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/bitt.png" alt="" />
                                </div>
                                <p class="font-semibold">Bittensor</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#08</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/bin.png" alt="" />
                                </div>
                                <p class="font-semibold">Binance</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#09</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/tet.png" alt="" />
                                </div>
                                <p class="font-semibold">Tether</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <p class="text-sm text-n70">#10</p>
                                <div
                                    class="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5"
                                >
                                    <img src="assets/images/Zcash.png" alt="" />
                                </div>
                                <p class="font-semibold">Zilliqa</p>
                            </div>
                            <div class="flex flex-col justify-end items-end">
                                <p class="font-semibold">$0.352416</p>
                                <p class="text-g300 text-sm">+3.00%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopGainers