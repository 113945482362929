import React from 'react'

function SuccessfullySent() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-center items-center text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="relative z-20 flex justify-center items-center flex-col px-6">
                    <div className="wave-animation">
                        <div className="size-32 rounded-full bg-g300 flex justify-center items-center">
                            <i className="ph ph-check relative z-[500] text-6xl text-black" />
                        </div>
                        <div className="waves wave-1" />
                        <div className="waves wave-2" />
                        <div className="waves wave-3" />
                    </div>
                    <div className="flex flex-col gap-3 pt-16 text-center">
                        <h1 className="text-2xl font-semibold">Successful Sent</h1>
                        <p className="text-sm text-n70 px-6">
                            Your transaction was successful. Confirmation details have been sent to
                            you.
                        </p>
                    </div>
                    <div className="w-full pt-20">
                        <a
                            href="/"
                            className="block bg-g300 font-semibold text-center py-3 rounded-lg"
                        >
                            Okey!
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SuccessfullySent