import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showLoadiing, setShowLoading] = useState(false);
  

  const handleLogin = async () => {

    setShowLoading(true);
    if (!userId || !password) {
      toast.error("Please fill in all fields");
      setShowLoading(false);
      return;

    }
    const postData = {
      userId: userId,
      password: password,
    };

    try {
      const response = await fetch('https://kaymcoin.com/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      console.log('Post Data',postData);
      const result = await response.json();

      console.log(result);

      if (result.status === true) {
        toast.success(result.message || "Login successful");

       
        localStorage.setItem('token', result.token);

        window.location.href = "/";

      } else {
        
        toast.error(result.message || "Login failed");
        setShowLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred while logging in");
      setShowLoading(false);
    }
  };

  return (
    <>
      <div className="container bg-n900 h-dvh relative overflow-hidden items-start text-white">
        <div className="px-6 py-8 relative z-20">
          <div className="flex justify-start items-center gap-20">
            <a
              href="/Register"
              className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
            >
              <i className="ph-bold ph-caret-left"></i>
            </a>
          </div>

          <div className="flex justify-center items-center flex-col gap-3 text- pt-8">
            <h1 className="text-2xl font-semibold">LOGIN</h1>
            <p className="text-n70 text-sm text-center">
              Secure with encrypted !
            </p>
          </div>

          <form className="pt-8 flex flex-col gap-4">
            <div className="">
              <p className="text-sm font-semibold pb-2">User ID/ Email</p>
              <div
                className="flex justify-between items-center gap-3 p-4 bg-white bg-opacity-5 rounded-lg text-n70 text-xl"
              >
                <input
                  type="text"
                  placeholder="Enter Email or User ID"
                  className="bg-transparent outline-none placeholder:text-sm text-sm passwordField"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
            </div>
            <div className="">
              <p className="text-sm font-semibold pb-2">Password</p>
              <div
                className="flex justify-between items-center gap-3 p-4 bg-white bg-opacity-5 rounded-lg text-n70 text-xl"
              >
                <input
                  type="password"
                  placeholder="******"
                  className="bg-transparent outline-none placeholder:text-sm text-sm confirmPasswordField"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </form>

          <div className="pt-8 flex justify-between items-center">
            <p className="font-medium">Don't have an account !</p>
            <div className="sign-in-toggle ">
              <h6><a href="/Register" className="text-primary">Register</a></h6>
            </div>
          </div>

          
        <div className="w-full pt-20">
          {showLoadiing ? (
            <div className="flex justify-center items-center flex-col gap-3 pt-8">
              <img
                className="block"
                src="assets/images/icons/lodingImage.webp"
                alt="loading icon"
                width="40%"
              />
            </div>
          ) :  (
            <a
              onClick={handleLogin}
              className="block bg-g300 font-semibold text-center py-3 rounded-lg"
            >
              Login
            </a>
          )  }
        </div>


        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
