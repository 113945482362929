import React, { useState } from 'react';
import {ToastContainer, toast } from 'react-toastify'; // For showing toast messages (optional)

function Register() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [password, setPassword] = useState('');
  const [showLoading, setShowLoading] = useState(false);

  const handleRegister = async () => {
    setShowLoading(true);

    // Validate fields
    if (!fullName || !email || !contactNumber || !password) {
      toast.error("Please fill in all fields");
      setShowLoading(false);
      return;
    }

    const postData = {
      full_name: fullName,
      email: email,
      mobile: contactNumber,
      referral: referralCode,
      password: password,
    };

    try {
      const response = await fetch('https://kaymcoin.com/api/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      const result = await response.json();
      console.log(result);

      if (result.status === true) {
        toast.success(result.message || "Registration successful");
        setTimeout(() => {
          window.location.href = "/Login"; // Redirect to the login page
        }, 5000); // 5000 milliseconds = 5 seconds
      } else {
        toast.error(result.message || "Registration failed");
      }
    } catch (error) {
      toast.error("An error occurred during registration");
      console.error(error);
    } finally {
      setShowLoading(false);
    }
  };
    return (
        <>
    <div class="container bg-n900 h-dvh relative overflow-hidden  items-start text-white">

      <div class="px-6 py-8 relative z-20">
        <div class="flex justify-start items-center gap-20">
          <a
            href="/Login"
            class="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
          >
            <i class="ph-bold ph-caret-left"></i>
          </a>
        </div>

        <div class="flex justify-center items-center flex-col text-">
          <h1 class="text-2xl font-semibold">REGISTER</h1>
          <p class="text-n70 text-sm text-center">
            Secure with incrypted !
          </p>
        </div>

        <form class="pt-8 flex flex-col gap-4" onSubmit={(e) => e.preventDefault()}>
        <div class="">
            <p class="text-sm font-semibold pb-2">Full Name</p>
            <div
              class="flex justify-between items-center gap-3 p-4 bg-white bg-opacity-5 rounded-lg text-n70 text-xl"
            >
              <input type="text" placeholder="Enter Full Name" class="bg-transparent outline-none placeholder:text-sm text-sm passwordField"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}/>
              
            </div>
          </div>
          <div class="">
            <p class="text-sm font-semibold pb-2">Email</p>
            <div
              class="flex justify-between items-center gap-3 p-4 bg-white bg-opacity-5 rounded-lg text-n70 text-xl"
            >
              <input type="text" placeholder="Enter Email " class="bg-transparent outline-none placeholder:text-sm text-sm passwordField"
              value={email}
              onChange={(e) => setEmail(e.target.value)}/>
              
            </div>
          </div>
          <div class="">
            <p class="text-sm font-semibold pb-2">Contact Number</p>
            <div
              class="flex justify-between items-center gap-3 p-4 bg-white bg-opacity-5 rounded-lg text-n70 text-xl"
            >
              <input type="text" placeholder="Enter Contact Number" class="bg-transparent outline-none placeholder:text-sm text-sm passwordField"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}/>
              
            </div>
          </div>

          <div class="">
            <p class="text-sm font-semibold pb-2">Referral Code</p>
            <div
              class="flex justify-between items-center gap-3 p-4 bg-white bg-opacity-5 rounded-lg text-n70 text-xl"
            >
              <input type="text" placeholder="Enter Referral Code" class="bg-transparent outline-none placeholder:text-sm text-sm passwordField"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}/>
              
            </div>
          </div>
          <div class="">
            <p class="text-sm font-semibold pb-2">Password</p>
            <div
              class="flex justify-between items-center gap-3 p-4 bg-white bg-opacity-5 rounded-lg text-n70 text-xl"
            >
              <input
                type="password"
                placeholder="******"
                class="bg-transparent outline-none placeholder:text-sm text-sm confirmPasswordField"
                value={password}
              onChange={(e) => setPassword(e.target.value)}
              />
              <div class="flex justify-start items-center gap-1">
                <i
                  class="ph ph-eye-closed cursor-pointer confirmPasswordShow"
                ></i>
              </div>
            </div>
          </div>
        </form>
        <div class="pt-8 flex justify-between items-center">
          <p class="font-medium">I have an account !</p>
          <div class="sign-in-toggle ">
            <h6><a  href="/Login" class="text-primary">Login</a></h6>
          </div>
        </div>
        <div class="w-full pt-5">
          <a
            onClick={handleRegister} className
            class="block bg-g300 font-semibold text-center py-3 rounded-lg"
          >
            Create
          </a>
        </div>
      </div>
    </div>
    <ToastContainer />
  </>
    )
}

export default Register