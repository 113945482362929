import React from 'react'

function Marketplace() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white pb-28">
                <div className="" />
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-center items-center pb-8">
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">Marketplace</h1>
                        </div>
                    </div>
                    <ul className="flex justify-start items-center gap-3 overflow-y-auto pt-4 vertical-scrollbar pb-3 browserCategory">
                        <li className="item active">
                            All <i className="ph ph-caret-right" />
                        </li>
                        <li className="item">
                            Art <i className="ph ph-caret-right" />
                        </li>
                        <li className="item">
                            Sport <i className="ph ph-caret-right" />
                        </li>
                        <li className="item">
                            People <i className="ph ph-caret-right" />
                        </li>
                        <li className="item">
                            Celebraties <i className="ph ph-caret-right" />
                        </li>
                    </ul>
                    <div className="grid grid-cols-2 gap-4 pt-5">
                        <div className="rounded-xl overflow-hidden col-span-1">
                            <img src="assets/images/nft-tab-img-1.png" alt="" />
                            <div className="bg-white bg-opacity-5 p-3 flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col gap-2">
                                    <p className="text-sm font-semibold">Abstract Lava</p>
                                    <div className="flex justify-center items-center bg-white bg-opacity-5 py-1 px-2 rounded-md gap-1">
                                        <img
                                            src="assets/images/Ethereum_ETH.png"
                                            alt=""
                                            className="h-4"
                                        />
                                        <p className="text-xs text-n70 font-medium">148.50k</p>
                                    </div>
                                </div>
                                <div className="flex justify-start items-center text-n70 text-sm">
                                    <i className="ph ph-heart-straight pr-1" />
                                    <span> 30</span>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-xl overflow-hidden col-span-1">
                            <img src="assets/images/nft-tab-img-2.png" alt="" />
                            <div className="bg-white bg-opacity-5 p-3 flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col gap-2">
                                    <p className="text-sm font-semibold">NexGenNFT</p>
                                    <div className="flex justify-center items-center bg-white bg-opacity-5 py-1 px-2 rounded-md gap-1">
                                        <img
                                            src="assets/images/Bitcoin_icon.png"
                                            alt=""
                                            className="h-4"
                                        />
                                        <p className="text-xs text-n70 font-medium">0.44k</p>
                                    </div>
                                </div>
                                <div className="flex justify-start items-center text-n70 text-sm">
                                    <i className="ph ph-heart-straight pr-1" />
                                    <span> 48</span>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-xl overflow-hidden col-span-1">
                            <img src="assets/images/nft-tab-img-2.png" alt="" />
                            <div className="bg-white bg-opacity-5 p-3 flex justify-between items-start">
                                <div className="flex justify-start items-start flex-col gap-2">
                                    <p className="text-sm font-semibold">CryptoBeasts</p>
                                    <div className="flex justify-center items-center bg-white bg-opacity-5 py-1 px-2 rounded-md gap-1">
                                        <img
                                            src="assets/images/Bitcoin_icon.png"
                                            alt=""
                                            className="h-4"
                                        />
                                        <p className="text-xs text-n70 font-medium">0.48k</p>
                                    </div>
                                </div>
                                <div className="flex justify-start items-center text-n70 text-sm">
                                    <i className="ph ph-heart-straight pr-1" />
                                    <span> 42</span>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-xl overflow-hidden col-span-1">
                            <img src="assets/images/nft-tab-img-1.png" alt="" />
                            <div className="bg-white bg-opacity-5 p-3 flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col gap-2">
                                    <p className="text-sm font-semibold">MintFusion</p>
                                    <div className="flex justify-center items-center bg-white bg-opacity-5 py-1 px-2 rounded-md gap-1">
                                        <img
                                            src="assets/images/Ethereum_ETH.png"
                                            alt=""
                                            className="h-4"
                                        />
                                        <p className="text-xs text-n70 font-medium">0.598k</p>
                                    </div>
                                </div>
                                <div className="flex justify-start items-center text-n70 text-sm">
                                    <i className="ph ph-heart-straight pr-1" />
                                    <span> 38</span>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-xl overflow-hidden col-span-1">
                            <img src="assets/images/nft-tab-img-1.png" alt="" />
                            <div className="bg-white bg-opacity-5 p-3 flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col gap-2">
                                    <p className="text-sm font-semibold">BlockArtHub</p>
                                    <div className="flex justify-center items-center bg-white bg-opacity-5 py-1 px-2 rounded-md gap-1">
                                        <img
                                            src="assets/images/Ethereum_ETH.png"
                                            alt=""
                                            className="h-4"
                                        />
                                        <p className="text-xs text-n70 font-medium">0.657k</p>
                                    </div>
                                </div>
                                <div className="flex justify-start items-center text-n70 text-sm">
                                    <i className="ph ph-heart-straight pr-1" />
                                    <span> 26</span>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-xl overflow-hidden col-span-1">
                            <img src="assets/images/nft-tab-img-2.png" alt="" />
                            <div className="bg-white bg-opacity-5 p-3 flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col gap-2">
                                    <p className="text-sm font-semibold">TokenTreasures</p>
                                    <div className="flex justify-center items-center bg-white bg-opacity-5 py-1 px-2 rounded-md gap-1">
                                        <img
                                            src="assets/images/Bitcoin_icon.png"
                                            alt=""
                                            className="h-4"
                                        />
                                        <p className="text-xs text-n70 font-medium">0.458k</p>
                                    </div>
                                </div>
                                <div className="flex justify-start items-center text-n70 text-sm">
                                    <i className="ph ph-heart-straight pr-1" />
                                    <span> 30</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Tab Area Start */}
                    <div className="fixed left-0 right-0 bottom-0">
                        <div className="container relative bg-white bg-opacity-5 py-5 flex justify-around items-center after:absolute after:bg-n700 after:inset-0">
                            <div className="absolute left-[41%] bottom-[72px] z-40">
                                <a
                                    href="#"
                                    className="bg-g300 text-2xl p-3.5 rounded-full flex justify-center items-center relative"
                                >
                                    <i className="ph ph-arrows-counter-clockwise" />
                                    <div className="absolute -bottom-2 -left-5 -right-5 -z-10">
                                        <img src="assets/images/reload-bg.png" alt="" />
                                    </div>
                                </a>
                            </div>
                            <a
                                href="/"
                                className="flex flex-col justify-center items-center gap-1 relative z-20"
                            >
                                <i className="ph ph-house text-2xl" />
                                <p className="text-xs font-semibold">Home</p>
                            </a>
                            <a
                                href="/browser"
                                className="flex flex-col justify-center items-center gap-1 z-20"
                            >
                                <i className="ph ph-globe text-2xl" />
                                <p className="text-xs font-semibold">Withdraw</p>
                            </a>
                            <a
                                href="/marketplace"
                                className="flex flex-col justify-center items-center gap-1 z-20"
                            >
                                <i className="ph ph-align-bottom text-2xl text-g300" />
                                <p className="text-xs font-semibold">Marketplace</p>
                            </a>
                            <a
                                href="/account"
                                className="flex flex-col justify-center items-center gap-1 z-20"
                            >
                                <i className="ph ph-user text-2xl" />
                                <p className="text-xs font-semibold">Account</p>
                            </a>
                        </div>
                    </div>
                    {/* Tab Area End */}
                </div>
            </div>

        </>
    )
}

export default Marketplace