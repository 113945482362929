import React from 'react'

function AutoLock() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-start items-center pb-8 mr-8">
                        <a
                            href="/account"
                            className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
                        >
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">Auto Lock</h1>
                        </div>
                    </div>
                    <p className="text-n70 text-sm pt-2">
                        Stay connected, follow teams, and never miss thrilling Stay connected
                    </p>
                    <div className="flex flex-col gap-4 pt-6 lockTimeItems">
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">Never</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">15 Seconds</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">30 Seconds</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">45 Seconds</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer active">
                            <p className="text-sm text-n70">1 Minute</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">5 Minute</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">30 Minute</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">1 Hour</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">10 Hour</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                        <div className="p-3 border border-white border-opacity-10 rounded-lg flex justify-between items-center item cursor-pointer">
                            <p className="text-sm text-n70">1 Day</p>
                            <div className="">
                                <i className="ph ph-check" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AutoLock