import React from 'react'

function BuySell() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="buySellTab pt-8 px-6 w-full relative z-20">
                    <ul className="tab-button flex justify-between items-center text-lg font-semibold">
                        <li
                            id="tabOne"
                            className="activeTabButton tabButton w-full text-center pb-2 border-b-2 border-n700"
                        >
                            Buy
                        </li>
                        <li
                            id="tabTwo"
                            className="tabButton w-full text-center pb-2 border-b-2 border-n700"
                        >
                            Sell
                        </li>
                    </ul>
                    <div className="pt-8">
                        <div className="tab-content activeTab" id="tabOne_data">
                            <div className="flex justify-between items-center gap-4 bg-white bg-opacity-5 rounded-lg py-3 px-4">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="outline-none bg-transparent w-full text-n70 placeholder:text-n70 text-sm"
                                />
                                <div className="">
                                    <i className="ph ph-magnifying-glass text-g300 text-xl" />
                                </div>
                              
                            </div>
                            <h2>Live Soon</h2>
                            {/* <div className="flex flex-col gap-2 pt-5">
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#01</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Ethereum_ETH.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Ethereum</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#02</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Bitcoin_icon.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Bitcoin</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#03</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Litecoin.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Litecoin</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#04</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Zcash.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Zilliqa</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#05</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Litecoin.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Worldcoin</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#06</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/VeChain.png" alt="" />
                                        </div>
                                        <p className="font-semibold">BitDAO</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#07</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/bitt.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Bittensor</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#08</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/bin.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Binance</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#09</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/tet.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Tether</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#10</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Zcash.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Zilliqa</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="tab-content hiddenTab" id="tabTwo_data">
                            <div className="flex justify-between items-center gap-4 bg-white bg-opacity-5 rounded-lg py-3 px-4">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="outline-none bg-transparent w-full text-n70 placeholder:text-n70 text-sm"
                                />
                                <div className="">
                                    <i className="ph ph-magnifying-glass text-g300 text-xl" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 pt-5">
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#01</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Ethereum_ETH.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Ethereum</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#02</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Bitcoin_icon.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Bitcoin</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#03</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Litecoin.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Litecoin</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#04</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Zcash.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Zilliqa</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#05</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Litecoin.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Worldcoin</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#06</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/VeChain.png" alt="" />
                                        </div>
                                        <p className="font-semibold">BitDAO</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#07</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/bitt.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Bittensor</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#08</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/bin.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Binance</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#09</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/tet.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Tether</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                    <div className="flex justify-start items-center gap-2">
                                        <p className="text-sm text-n70">#10</p>
                                        <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                            <img src="assets/images/Zcash.png" alt="" />
                                        </div>
                                        <p className="font-semibold">Zilliqa</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">$0.352416</p>
                                        <p className="text-g300 text-sm">+3.00%</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default BuySell