import React from 'react'

function AddressBook() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh overflow-hidden flex justify-start items-start text-white relative">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-start items-center gap-20 pb-5">
                        <a
                            href="/account"
                            className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
                        >
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-start items-center">
                            <h1 className="font-semibold text-xl">Address Book</h1>
                        </div>
                    </div>
                    <div className="homeTab pt-8">
                        <ul className="tab-button flex justify-between items-center text-lg font-semibold">
                            <li
                                id="tabOne"
                                className="activeTabButton tabButton w-full text-center pb-2 border-b-2 border-n700"
                            >
                                My Accounts
                            </li>
                            <li
                                id="tabTwo"
                                className="tabButton w-full text-center pb-2 border-b-2 border-n700"
                            >
                                Contacts
                            </li>
                        </ul>
                        <div className="pt-8">
                            <div className="tab-content activeTab" id="tabOne_data">
                                <div className="flex justify-start gap-2 overflow-x-auto vertical-scrollbar pb-3">
                                    <div className="p-3 border border-white border-opacity-5 flex justify-center items-center rounded-md">
                                        <div className="flex justify-center items-center bg-white bg-opacity-5 rounded-full size-10">
                                            <i className="ph ph-sliders-horizontal text-g300" />
                                        </div>
                                    </div>
                                    <div className="p-3 border border-white border-opacity-5 flex flex-col justify-center items-center rounded-md">
                                        <div className="flex justify-center items-center bg-white bg-opacity-5 rounded-full size-10">
                                            <img src="assets/images/crypto-icon.png" alt="" className="" />
                                        </div>
                                        <p className="text-sm font-semibold pt-1">ZIL</p>
                                    </div>
                                    <div className="p-3 border border-white border-opacity-5 flex flex-col justify-center items-center rounded-md">
                                        <div className="flex justify-center items-center bg-white bg-opacity-5 rounded-full size-10">
                                            <img src="assets/images/crypto_icon.png" alt="" className="" />
                                        </div>
                                        <p className="text-sm font-semibold pt-1">BITT</p>
                                    </div>
                                    <div className="p-3 border border-white border-opacity-5 flex flex-col justify-center items-center rounded-md">
                                        <div className="flex justify-center items-center bg-white bg-opacity-5 rounded-full size-10">
                                            <img src="assets/images/Ethereum_ETH.png" alt="" className="" />
                                        </div>
                                        <p className="text-sm font-semibold pt-1">ETH</p>
                                    </div>
                                    <div className="p-3 border border-white border-opacity-5 flex flex-col justify-center items-center rounded-md">
                                        <div className="flex justify-center items-center bg-white bg-opacity-5 rounded-full size-10">
                                            <img src="assets/images/NEO.png" alt="" className="" />
                                        </div>
                                        <p className="text-sm font-semibold pt-1">TET</p>
                                    </div>
                                    <div className="p-3 border border-white border-opacity-5 flex flex-col justify-center items-center rounded-md">
                                        <div className="flex justify-center items-center bg-white bg-opacity-5 rounded-full size-10">
                                            <img src="assets/images/Litecoin.png" alt="" className="" />
                                        </div>
                                        <p className="text-sm font-semibold pt-1">MAK</p>
                                    </div>
                                </div>
                                <div className="p-5 rounded-xl mt-8 bg-white bg-opacity-5 flex justify-between items-center accountOptionModalOpenButton">
                                    <div className="">
                                        <p className="font-semibold">KLV Main Account</p>
                                        <p className="text-n70 text-xs">klv1s0....6j7zay</p>
                                    </div>
                                    <div className="flex flex-col justify-end items-end">
                                        <p className="font-semibold">0.00</p>
                                        <p className="text-n70 text-xs">=0.00 USD</p>
                                    </div>
                                </div>
                                <div className="w-full pt-40">
                                    <a
                                        href="#"
                                        className="block bg-g300 font-semibold text-center py-3 rounded-lg"
                                    >
                                        Create New Account
                                    </a>
                                </div>
                            </div>
                            <div className="tab-content hiddenTab" id="tabTwo_data">
                                <div className="flex flex-col justify-center items-center pt-6 text-center">
                                    <img src="assets/images/not-found-illus.png" alt="" />
                                    <h1 className="text-2xl font-semibold pt-10">
                                        Nothing to show here.
                                    </h1>
                                    <p className="text-sm text-n70 pt-10">
                                        You don’t have any contact listed here yet.
                                    </p>
                                    <div className="w-full pt-20">
                                        <a
                                            href="#"
                                            className="block bg-g300 font-semibold text-center py-3 rounded-lg"
                                        >
                                            Add Contact
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden inset-0 z-40 accountOptionModal">
                    <div className="container bg-white bg-opacity-10 flex justify-end items-end h-full">
                        <div className="bg-n900 px-6 pt-3 pb-6 w-full rounded-t-[31px] relative">
                            <div className="flex justify-center items-center">
                                <div className="w-16 h-1 bg-white bg-opacity-5 rounded-full" />
                            </div>
                            <h2 className="text-xl font-semibold text-center pt-8">
                                Account Option
                            </h2>
                            <button className="absolute top-4 right-4 text-2xl text-g300 accountOptionModalCloseButton">
                                <i className="ph ph-x-circle" />
                            </button>
                            <div className="flex flex-col gap-4 pt-8">
                                <a className="rounded-lg p-3 border border-white border-opacity-5 hover:bg-white hover:bg-opacity-5 duration-300 group">
                                    <div className="flex justify-start items-center gap-3">
                                        <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                            <i className="ph ph-copy" />
                                        </div>
                                        <p className="font-semibold">Copy Address</p>
                                    </div>
                                </a>
                                <a className="rounded-lg p-3 border border-white border-opacity-5 hover:bg-white hover:bg-opacity-5 duration-300 group">
                                    <div className="flex justify-start items-center gap-3">
                                        <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                            <i className="ph ph-pencil-simple-line" />
                                        </div>
                                        <p className="font-semibold">Edit</p>
                                    </div>
                                </a>
                                <a className="rounded-lg p-3 border border-white border-opacity-5 hover:bg-white hover:bg-opacity-5 duration-300 group">
                                    <div className="flex justify-start items-center gap-3">
                                        <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                            <i className="ph ph-trash-simple" />
                                        </div>
                                        <p className="font-semibold">Delete</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddressBook