import React from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Importing QRCodeCanvas instead of QRCode
import {ToastContainer, toast } from 'react-toastify'; 

function Received() {
    const walletAddress = localStorage.getItem('userWallet') || '';

    // Function to copy wallet address to clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(walletAddress).then(() => {
            toast.success('Wallet address copied to clipboard!'); // Optional: Show a confirmation message
        }).catch((err) => {
            console.error('Could not copy text: ', err);
        });
    };

    // Function to share wallet address
    const shareWalletAddress = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Share Wallet Address',
                text: 'Here is my wallet address:',
                url: walletAddress, // You can format this URL based on your requirements
            }).then(() => {
                console.log('Successfully shared');
            }).catch((error) => {
                console.error('Error sharing: ', error);
            });
        } else {
            alert('Sharing is not supported in your browser.');
        }
    };

    return (
        <>
            <div className="container bg-n900 relative overflow-hidden flex justify-start items-start text-white pb-36">
                <div className="px-6 py-8 relative w-full">
                    <div className="flex justify-start items-center pb-8 mr-8">
                        <a
                            href="/"
                            className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
                        >
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">Received</h1>
                        </div>
                    </div>
                    <div className="flex justify-center items-center pt-12">
                        <QRCodeCanvas 
                            value={walletAddress} 
                            size={256} // You can adjust the size as needed
                            level="L" // Error correction level (L, M, Q, H)
                        />
                    </div>
                    <div className="flex justify-center items-start gap-1 pt-8">
                        <p className="text-xl font-semibold">BEP 20 Address</p>
                    </div>
                    <p className="text-sm text-n70 text-center pt-4">
                        Don't risk losing your funds. Protect your wallet by saving your address
                        in a place you trust.
                    </p>
                    <div className="flex justify-center items-center gap-2 pt-6">
                        <div className="flex justify-center items-center gap-1 py-2 px-4 bg-white bg-opacity-5 rounded-md" onClick={copyToClipboard}>
                            <p className="text-xs text-n70">
                                {walletAddress.slice(0, 10) + '...' + walletAddress.slice(-10)}
                            </p>
                            <i className="ph ph-copy text-g300 cursor-pointer" />
                        </div>
                    </div>
                    <div className="w-full pt-20">
                        <a
                            onClick={shareWalletAddress}
                            className="block bg-g300 font-semibold text-center py-3 rounded-lg"
                        >
                            SHARE
                        </a>
                    </div>
                    
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default Received;
