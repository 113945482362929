import React from 'react'

function InputAmount() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-center items-center flex-col gap-2 w-full">
                        <h1 className="text-2xl font-semibold text-center pb-2">Input Amount</h1>
                        <div className="px-4 py-2 rounded-lg bg-white bg-opacity-5 flex justify-between items-center gap-2">
                            <div className="flex justify-start items-center gap-1">
                                <img src="assets/images/1INCH_icon.png" alt="" className="h-5" />
                                <p className="text-n70 text-xs uppercase">1INCH Main network</p>
                            </div>
                            <i className="ph ph-caret-down text-g300" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center my-8 w-full border-y border-white border-opacity-5 border-dashed py-8">
                        <div className="flex justify-start items-center gap-2">
                            <img src="assets/images/1INCH_icon.png" alt="" />
                            <p className="text-[32px] font-bold text-white relative">
                                210.44
                                <span className="text-sm font-normal text-g300 absolute top-1 -right-14">
                                    (1INCH)
                                </span>
                            </p>
                        </div>
                        <p className="text-sm text-n70">
                            <span className="text-g300">$</span>210.44 (USD)
                        </p>
                    </div>
                    <div className="w-full pt-20">
                        <button className="block bg-g300 font-semibold text-center py-3 rounded-lg tokenModalOpenButton w-full">
                            Next
                        </button>
                    </div>
                    <div className="hidden inset-0 z-40 tokenModal">
                        <div className="container bg-white bg-opacity-10 flex justify-end items-end h-full">
                            <div className="bg-n900 px-6 pt-3 pb-6 w-full rounded-t-[31px] relative">
                                <div className="flex justify-center items-center">
                                    <div className="w-16 h-1 bg-white bg-opacity-5 rounded-full" />
                                </div>
                                <h2 className="text-xl font-semibold text-center pt-8 pb-5">Token</h2>
                                <div className="flex flex-col gap-2 sendModalOpenButton tokenModalCloseButton">
                                    <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                        <div className="flex justify-start items-center gap-2">
                                            <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                                <img src="assets/images/Zcash.png" alt="" />
                                            </div>
                                            <p className="font-semibold">Zcash</p>
                                        </div>
                                        <div className="flex flex-col justify-end items-end">
                                            <p className="font-semibold">$0.352416</p>
                                            <p className="text-sm text-n70">
                                                <span className="text-g300">$</span>65.00
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                        <div className="flex justify-start items-center gap-2">
                                            <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                                <img src="assets/images/Zcash.png" alt="" />
                                            </div>
                                            <p className="font-semibold">DASH</p>
                                        </div>
                                        <div className="flex flex-col justify-end items-end">
                                            <p className="font-semibold">$0.352416</p>
                                            <p className="text-sm text-n70">
                                                <span className="text-g300">$</span>65.00
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center bg-white bg-opacity-5 p-4 rounded-xl">
                                        <div className="flex justify-start items-center gap-2">
                                            <div className="text-g300 flex justify-center items-center size-10 rounded-full text-xl bg-white bg-opacity-5">
                                                <img src="assets/images/bitt.png" alt="" />
                                            </div>
                                            <p className="font-semibold">ETH</p>
                                        </div>
                                        <div className="flex flex-col justify-end items-end">
                                            <p className="font-semibold">$0.352416</p>
                                            <p className="text-sm text-n70">
                                                <span className="text-g300">$</span>65.00
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden inset-0 z-40 sendModal">
                        <div className="container bg-white bg-opacity-10 flex justify-end items-end h-full">
                            <div className="bg-n900 px-6 pt-3 pb-6 w-full rounded-t-[31px] relative">
                                <div className="flex justify-center items-center">
                                    <div className="w-16 h-1 bg-white bg-opacity-5 rounded-full" />
                                </div>
                                <h2 className="text-xl font-semibold text-center pt-8 pb-5">
                                    Send 1INCH
                                </h2>
                                <div className="bg-white bg-opacity-5 p-4 rounded-xl flex flex-col gap-5 text-sm">
                                    <div className="flex justify-between items-center pb-5 border-b border-white border-dashed border-opacity-10">
                                        <p className="text-n70">Amount</p>
                                        <p>0.1802 1INCH</p>
                                    </div>
                                    <div className="flex justify-between items-center pb-5 border-b border-white border-dashed border-opacity-10">
                                        <p className="text-n70">Status</p>
                                        <p className="text-g300">Confirmed</p>
                                    </div>
                                    <div className="flex justify-between items-center pb-5 border-b border-white border-dashed border-opacity-10">
                                        <p className="text-n70">Date</p>
                                        <p>Dec 24, 2023 09:40 AM</p>
                                    </div>
                                    <div className="flex justify-between items-center pb-5 border-b border-white border-dashed border-opacity-10">
                                        <p className="text-n70">From</p>
                                        <p>0x763D...fdF9</p>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <p className="text-n70">To</p>
                                        <p>OxBBB...e96e</p>
                                    </div>
                                </div>
                                <div className="w-full pt-20">
                                    <a
                                        href="/successfully-sent"
                                        className="block bg-g300 font-semibold text-center py-3 rounded-lg"
                                    >
                                        Save
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default InputAmount