import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Authentication = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true); // Manage the loading state

  useEffect(() => {
    // Get the URL parameters
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("authKey");

    // Store the data in localStorage
    if (token) {
      localStorage.setItem("token", token);

      // Set timeout for 3 seconds to simulate loading, then redirect
      setTimeout(() => {
        setLoading(false); // Stop the loader
        window.location.href = "/";
      }, 3000); // 3 seconds delay
    }

    // Optionally, log for verification
    console.log("Token:", token);
  }, [location]);

  return (
    <div>
      {loading ? (
        <div className="loader">Loading...</div> // Show loader while redirecting
      ) : (
        <div>Redirecting...</div>
      )}
    </div>
  );
};

export default Authentication;
