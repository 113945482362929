import React from 'react'

function ReceivedAmount() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-center items-center flex-col gap-2 w-full">
                        <h1 className="text-2xl font-semibold text-center pb-2">Input Amount</h1>
                        <div className="px-4 py-2 rounded-lg bg-white bg-opacity-5 flex justify-between items-center gap-2">
                            <div className="flex justify-start items-center gap-1">
                                <img src="assets/images/1INCH_icon.png" alt="" className="h-5" />
                                <p className="text-n70 text-xs uppercase">1INCH Main network</p>
                            </div>
                            <i className="ph ph-caret-down text-g300" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center my-8 w-full border-y border-white border-opacity-5 border-dashed py-8">
                        <div className="flex justify-start items-center gap-2">
                            <img src="assets/images/1INCH_icon.png" alt="" />
                            <p className="text-[32px] font-bold text-white relative">
                                210.44
                                <span className="text-sm font-normal text-g300 absolute top-1 -right-14">
                                    (1INCH)
                                </span>
                            </p>
                        </div>
                        <p className="text-sm text-n70">
                            <span className="text-g300">$</span>210.44 (USD)
                        </p>
                    </div>
                    <div className="w-full pt-20">
                        <a
                            href="successfully-sent"
                            className="block bg-g300 font-semibold text-center py-3 rounded-lg tokenModalOpenButton w-full"
                        >
                            Next
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ReceivedAmount