import React from 'react'

function SendTo() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-start items-center pb-8 mr-8">
                        <a
                            href="import-seed.html"
                            className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
                        >
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">Send To</h1>
                        </div>
                    </div>
                    <div className="">
                        <p className="text-sm font-semibold pb-2">From</p>
                        <a
                            href="send-to.html"
                            className="w-full flex justify-between items-center gap-6 border border-white border-opacity-5 bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div className="flex justify-start items-center gap-3">
                                <img src="assets/images/account-img-3.png" alt="" />
                                <div className="">
                                    <p className="font-semibold">CoinCollector</p>
                                    <p className="text-n70 text-sm">
                                        Balance: 2.548546 <span className="text-g300">(1INCH)</span>
                                    </p>
                                </div>
                            </div>
                            <i className="ph ph-caret-right text-g300" />
                        </a>
                    </div>
                    <div className="pt-6">
                        <p className="text-sm font-semibold pb-2">To</p>
                        <a
                            href="send-to.html"
                            className="w-full flex justify-between items-center gap-6 border border-white border-opacity-5 bg-white bg-opacity-5 p-4 rounded-xl"
                        >
                            <div className="flex justify-start items-center gap-3">
                                <img src="assets/images/account-img-2.png" alt="" />
                                <div className="">
                                    <p className="font-semibold">DigitalNomad</p>
                                    <p className="text-n70 text-sm">0x3R2E...DxR9</p>
                                </div>
                            </div>
                            <i className="ph ph-caret-right text-g300" />
                        </a>
                    </div>
                    <div className="w-full pt-20">
                        <a
                            href="/input-amount"
                            className="block bg-g300 font-semibold text-center py-3 rounded-lg"
                        >
                            Next
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SendTo