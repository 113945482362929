import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function SecureWallet() {


    const location = useLocation();
  const [loading, setLoading] = useState(true); // Manage the loading state

  useEffect(() => {
    // Get the URL parameters
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("authKey");

    // Store the data in localStorage
    if (token) {
      localStorage.setItem("token", token);

      // Set timeout for 3 seconds to simulate loading, then redirect
      setTimeout(() => {
        setLoading(false); // Stop the loader
        window.location.href = "/";
      }, 3000); // 3 seconds delay
    }

    // Optionally, log for verification
    console.log("Token:", token);
  }, [location]);
    return (
        <>
            <div className="container bg-n900 h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="px-6 py-8 relative z-20 w-full">
                   
                    <div className="flex justify-center items-center pt-10">
                        <img src="assets/images/sheild-image.png" alt="" />
                    </div>
                    <div className="flex justify-center items-center flex-col text-center pt-8">
                        <h2 className="text-xl">Checking...</h2>
                        
                    </div>
                    <div className="w-full pt-20 flex justify-between items-center gap-4">
                    <div className="flex justify-center items-center flex-col gap-3 pt-8">
              <img
                className="block"
                src="assets/images/icons/lodingImage.webp"
                alt="loading icon"
                width="40%"
              />
            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecureWallet