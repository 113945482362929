import React from 'react'

function BackupWallet() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-start items-center pb-8 mr-8">
                        <a
                            href="/account"
                            className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
                        >
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">Backup Wallet</h1>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center text-center pt-8">
                        <img src="assets/images/lock-1.png" alt="" />
                        <h1 className="text-2xl font-semibold pt-8">Security Alert</h1>
                        <p className="text-sm text-n70 pt-3">
                            Reset your access effortlessly and regain control with our password
                            recovery service.
                        </p>
                    </div>
                    <div className="pt-20 flex gap-3">
                        <div className="sign-in-toggle toggle active">
                            <div className="circle" />
                        </div>
                        <p className="text-xs text-n70">
                            I know sharing this information could jeopardize the security of my
                            account.
                        </p>
                    </div>
                    <div className="w-full pt-20">
                        <a
                            href="#"
                            className="block bg-g300 font-semibold text-center py-3 rounded-lg"
                        >
                            Okey!
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BackupWallet