import React from 'react'

function CustomNetwork() {
    return (
        <>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="w-[582px] h-[582px] rounded-full bg-g300 absolute -top-32 -left-20 blur-[575px]" />
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-start items-center pb-8 mr-8">
                        <a
                            href="/account"
                            className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900"
                        >
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">Custom Network</h1>
                        </div>
                    </div>
                    <p className="text-sm text-n70">
                        Stay connected, follow teams, and never miss thrilling Stay connected
                    </p>
                    <div className="pt-5">
                        <p className="text-sm font-semibold pb-2">Chain ID</p>
                        <div className="p-4 bg-white bg-opacity-5 rounded-xl">
                            <input
                                type="text"
                                className="outline-none text-sm text-n70 placeholder:text-n70 bg-transparent"
                            />
                        </div>
                        <p className="text-xs text-n70 pt-2">
                            I understand that DeSquid cannot recover this password for me. LearmoreI
                            understand that DeSquid cannot recover this password for me. Learmore
                        </p>
                    </div>
                    <div className="pt-5">
                        <p className="text-sm font-semibold pb-2">Network Name</p>
                        <div className="p-4 bg-white bg-opacity-5 rounded-xl">
                            <input
                                type="text"
                                className="outline-none text-sm text-n70 placeholder:text-n70 bg-transparent"
                            />
                        </div>
                    </div>
                    <div className="pt-5">
                        <p className="text-sm font-semibold pb-2">New RPC URL</p>
                        <div className="p-4 bg-white bg-opacity-5 rounded-xl">
                            <input
                                type="text"
                                className="outline-none text-sm text-n70 placeholder:text-n70 bg-transparent"
                            />
                        </div>
                    </div>
                    <div className="pt-5">
                        <p className="text-sm font-semibold pb-2">Currency Symbol</p>
                        <div className="p-4 bg-white bg-opacity-5 rounded-xl">
                            <input
                                type="text"
                                className="outline-none text-sm text-n70 placeholder:text-n70 bg-transparent"
                            />
                        </div>
                        <p className="text-xs text-n70 pt-2">
                            Max currency symbol is 10 characters
                        </p>
                    </div>
                    <div className="pt-5">
                        <p className="text-sm font-semibold pb-2">
                            Block explorer URL (optional)
                        </p>
                        <div className="p-4 bg-white bg-opacity-5 rounded-xl">
                            <input
                                type="text"
                                className="outline-none text-sm text-n70 placeholder:text-n70 bg-transparent"
                            />
                        </div>
                    </div>
                    <div className="flex justify-start items-start gap-3 pt-8 customCheckbox">
                        <input id="check" type="checkbox" className="" />
                        <label htmlFor="check" className="">
                            I understand that DeSquid cannot recover this password for me. Learmore
                        </label>
                    </div>
                    <div className="w-full pt-10">
                        <a
                            href="#"
                            className="block bg-g300 font-semibold text-center py-3 rounded-lg"
                        >
                            Save
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomNetwork